import { template as template_032c6c7677144219888ca530f06d2032 } from "@ember/template-compiler";
const FKControlMenuContainer = template_032c6c7677144219888ca530f06d2032(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
