import { template as template_acb63c277dcf4088aba81df6d79d94e9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_acb63c277dcf4088aba81df6d79d94e9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
